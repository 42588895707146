/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* https://tailwindcss.com/docs/adding-new-utilities */
/* @layer utilities {
    .scroll-snap-none {
      scroll-snap-type: none;
    }
    .scroll-snap-x {
      scroll-snap-type: x;
    }
    .scroll-snap-y {
      scroll-snap-type: y;
    }
  } */

/* https://tailwindcss.com/docs/extracting-components */
@layer components {
  /* .btn-blue {
        @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
    } */
  .tooltip {
    @apply invisible absolute -mt-11 -ml-12 whitespace-nowrap rounded-lg border border-gray-200 bg-gray-light p-1 px-4 py-2 text-sm text-secondary shadow-lg;
  }
  .tooltip-topic {
    @apply invisible absolute -mt-3 border-2 border-background-dark bg-white p-1 px-4 py-2 text-sm text-gray-dark shadow;
  }
  .has-tooltip:hover .tooltip {
    @apply visible z-50;
  }
  .has-tooltip:active .tooltip-topic {
    @apply visible z-50;
  }
  .has-tooltip:hover .tooltip-topic {
    @apply visible z-50;
  }
}

/* ToolTip  */
#va-link {
  border-style: none;
  border: none !important;
}

#va-linkbox {
  position: relative;
  display: inline-block;
  font-family: "Space Grotesk", Arial, sans-serif;
  letter-spacing: 0.5px;
}

#va-linkbox #va-tooltip {
  visibility: hidden;
  width: 140px;
  background-color: rgb(195, 195, 195);
  color: #000;
  text-align: center;
  border-radius: 0.25rem;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
}
#va-linkbox #va-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

#va-linkbox:hover #va-tooltip {
  visibility: visible;
  opacity: 1;
}

.social-svg {
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
}

button.google-login {
  border: 1px solid white !important;
}

button.google-login:hover {
  border: 1px solid #4285f4 !important;
  color: #4285f4;
}
