/* Custom CSS */
.break-word {
  word-break: break-word;
}

.w-fit-content {
  width: fit-content;
}

.center-element-in-middle {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(
    -50%,
    -50%
  ); /* This is a shorthand of
                                         translateX(-50%) and translateY(-50%) */
}

.slider-thumb::-webkit-slider-thumb {
  @apply appearance-none bg-gray-dark w-3 h-3 rounded-full cursor-pointer;
}

.slider-thumb-secondary::-webkit-slider-thumb:hover {
  @apply bg-secondary;
}
.slider-thumb-warning::-webkit-slider-thumb:hover {
  @apply bg-warning;
}
/* .stick {
  position: fixed;
  top: 225px;
} */
